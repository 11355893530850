import React, { Fragment } from "react";
import SEO from "../Components/seo";
import styled from "styled-components";

export default function Maintenance() {
  return (
    <Wrapper>
      <SEO title={`維護中 | 感官文化`} />
      <img src="/images/under-construction.jpeg" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  & > img {
    width: min(90%, ${1320 * 0.9}px);
    display: block;
    margin: 0 auto;
  }
`;
